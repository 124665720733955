import { animated, useSprings } from 'react-spring'

export function CherryBlossomVisual({ rotation = 3, style = undefined, layoutClassName = undefined }) {
  const [springs] = useSprings(6, i => ({
    from: { transformOrigin: 'bottom', rotate: 0 },
    to: [
      {
        delay: 200,
        transformOrigin: 'bottom',
        rotate: i < 3 ? ((i + 1) * rotation) : ((i + 1) - 3) * -rotation
      },
      {
        transformOrigin: 'bottom',
        rotate: 0
      }
    ],
    config: { duration: 650, easing: easeBreathe },
    loop: true
  }))

  const { fill, stroke } = style ?? {}

  return (
    <svg
      role='img'
      aria-label='Little acts of kindness icon'
      className={layoutClassName}
      width="61"
      height="37"
      viewBox="0 0 54 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Right */}
      <animated.path style={springs[0]} d="M37.6719 20.8874C36.5098 25.2247 33.9464 28.7692 31.6508 31.2415C30.5055 32.4749 29.4338 33.434 28.65 34.0836C28.3903 34.2988 28.1624 34.4798 27.9743 34.6254C27.8841 34.4053 27.7773 34.1346 27.66 33.8184C27.306 32.8639 26.8574 31.4974 26.4823 29.8567C25.7304 26.5677 25.2828 22.2164 26.4449 17.8792C27.6071 13.5419 30.1704 9.9974 32.4661 7.52505C33.6113 6.29164 34.683 5.33254 35.4669 4.68296C35.7266 4.46776 35.9544 4.28674 36.1426 4.14117C36.2327 4.36132 36.3395 4.63201 36.4568 4.94821C36.8109 5.9027 37.2594 7.26913 37.6345 8.90992C38.3864 12.1989 38.8341 16.5502 37.6719 20.8874Z" fill={fill || 'url(#paint0_linear_1021_708)'} stroke={stroke || 'var(--primary-color, white)'} strokeWidth="1.2" />
      <animated.path style={springs[2]} d="M41.4282 30.1947C37.5399 32.4396 33.2191 33.1298 29.8455 33.2511C28.1624 33.3116 26.7254 33.2301 25.711 33.1338C25.3736 33.1018 25.0833 33.0681 24.8466 33.0376C24.9385 32.8173 25.0545 32.5491 25.1954 32.2408C25.6192 31.3142 26.2672 30.029 27.1611 28.6017C28.9529 25.7407 31.711 22.3439 35.5994 20.099C39.4878 17.854 43.8086 17.1638 47.1822 17.0426C48.8652 16.9821 50.3022 17.0635 51.3166 17.1598C51.654 17.1919 51.9444 17.2255 52.1811 17.2561C52.0892 17.4764 51.9732 17.7446 51.8322 18.0528C51.4084 18.9795 50.7604 20.2647 49.8665 21.692C48.0747 24.553 45.3166 27.9498 41.4282 30.1947Z" fill={fill || 'url(#paint1_linear_1021_708)'} stroke={stroke || 'var(--primary-color, white)'} strokeWidth="1.2" />
      <animated.path style={springs[1]} d="M41.2084 22.2897C38.9633 26.1783 35.5691 28.938 32.7109 30.7315C31.285 31.6262 30.0012 32.275 29.0756 32.6994C28.7687 32.8401 28.5014 32.956 28.2818 33.048C28.2516 32.8118 28.2184 32.5224 28.1868 32.1862C28.0915 31.1725 28.0115 29.7362 28.0734 28.054C28.1975 24.682 28.8904 20.3627 31.1355 16.4741C33.3806 12.5855 36.7748 9.82575 39.633 8.03229C41.0589 7.13757 42.3427 6.48878 43.2683 6.06439C43.5753 5.92367 43.8425 5.80775 44.0621 5.71584C44.0923 5.952 44.1256 6.24139 44.1572 6.57755C44.2524 7.59134 44.3325 9.02756 44.2706 10.7098C44.1465 14.0818 43.4535 18.4011 41.2084 22.2897Z" fill={fill || 'url(#paint5_linear_1021_708)'} stroke={stroke || 'var(--primary-color, white)'} strokeWidth="1.2" />

      {/* Left */}
      <animated.path style={springs[3]} d="M29.6717 17.8787C30.8338 22.2159 30.3862 26.5672 29.6343 29.8562C29.2592 31.497 28.8106 32.8634 28.4566 33.8179C28.3393 34.1341 28.2325 34.4048 28.1423 34.6249C27.9541 34.4794 27.7263 34.2984 27.4666 34.0832C26.6828 33.4336 25.6111 32.4745 24.4658 31.2411C22.1702 28.7687 19.6068 25.2242 18.4447 20.8869C17.2825 16.5497 17.7301 12.1984 18.482 8.90945C18.8572 7.26867 19.3057 5.90223 19.6598 4.94774C19.7771 4.63154 19.8839 4.36085 19.974 4.1407C20.1622 4.28627 20.39 4.46729 20.6497 4.68249C21.4336 5.33208 22.5052 6.29117 23.6505 7.52458C25.9462 9.99694 28.5095 13.5415 29.6717 17.8787Z" fill={fill || 'url(#paint2_linear_1021_708)'} stroke={stroke || 'var(--primary-color, white)'} strokeWidth="1.2" />
      <animated.path style={springs[4]} d="M18.233 20.0919C22.1212 22.3367 24.8781 25.7353 26.6688 28.5982C27.5622 30.0264 28.2096 31.3126 28.633 32.24C28.7741 32.5491 28.8902 32.8181 28.9821 33.0388C28.745 33.0695 28.454 33.1035 28.1158 33.1358C27.1009 33.2329 25.6634 33.3153 23.9798 33.2557C20.6051 33.1364 16.2833 32.4481 12.3951 30.2033C8.50691 27.9584 5.74997 24.5599 3.95926 21.6969C3.06592 20.2687 2.4185 18.9825 1.99513 18.0552C1.85401 17.7461 1.73792 17.4771 1.64603 17.2564C1.88311 17.2256 2.17408 17.1917 2.51234 17.1593C3.52716 17.0623 4.96474 16.9799 6.64829 17.0394C10.023 17.1588 14.3448 17.847 18.233 20.0919Z" fill={fill || 'url(#paint3_linear_1021_708)'} stroke={stroke || 'var(--primary-color, white)'} strokeWidth="1.2" />
      <animated.path style={springs[5]} d="M24.4071 16.4767C26.6522 20.3654 27.346 24.6843 27.471 28.0557C27.5333 29.7377 27.4537 31.1737 27.3588 32.1873C27.3273 32.5231 27.2942 32.8122 27.2641 33.0482C27.0447 32.9563 26.7777 32.8404 26.4712 32.6997C25.5459 32.2751 24.2625 31.6261 22.8371 30.7311C19.9797 28.9372 16.5863 26.1769 14.3412 22.2882C12.0961 18.3995 11.4022 14.0806 11.2773 10.7091C11.2149 9.02718 11.2946 7.59121 11.3895 6.57761C11.421 6.24183 11.4541 5.95272 11.4842 5.71673C11.7036 5.80865 11.9705 5.92453 12.2771 6.06518C13.2023 6.48976 14.4857 7.1388 15.9112 8.03377C18.7685 9.82773 22.1619 12.588 24.4071 16.4767Z" fill={fill || 'url(#paint4_linear_1021_708)'} stroke={stroke || 'var(--primary-color, white)'} strokeWidth="1.2" />

      {/* Center */}
      <path d="M33.7313 16.7999C33.7313 21.3061 32.1751 25.4075 30.6003 28.3998C29.8146 29.8927 29.0291 31.1005 28.4411 31.9337C28.2471 32.2085 28.0747 32.4424 27.9313 32.6319C27.7878 32.4424 27.6155 32.2085 27.4215 31.9337C26.8335 31.1005 26.0479 29.8927 25.2622 28.3998C23.6874 25.4075 22.1312 21.3061 22.1312 16.7999C22.1312 12.2937 23.6874 8.19232 25.2622 5.20002C26.0479 3.70718 26.8335 2.49932 27.4215 1.6662C27.6155 1.39132 27.7878 1.15749 27.9313 0.967945C28.0747 1.15749 28.2471 1.39132 28.4411 1.6662C29.0291 2.49932 29.8146 3.70718 30.6003 5.20002C32.1751 8.19232 33.7313 12.2937 33.7313 16.7999Z" fill={fill || 'url(#paint6_linear_1021_708)'} stroke={stroke || 'var(--primary-color, white)'} strokeWidth="1.2" />

      <defs>
        <linearGradient id="paint0_linear_1021_708" x1="25.9176" y1="17.529" x2="38.617" y2="21.4079" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E3B356" />
          <stop offset="1" stopColor="#8B6A2D" />
        </linearGradient>
        <linearGradient id="paint1_linear_1021_708" x1="35.4742" y1="19.4785" x2="41.7268" y2="31.2332" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E3B356" />
          <stop offset="1" stopColor="#8B6A2D" />
        </linearGradient>
        <linearGradient id="paint2_linear_1021_708" x1="17.8129" y1="20.8473" x2="30.7503" y2="17.8569" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E3B356" />
          <stop offset="1" stopColor="#8B6A2D" />
        </linearGradient>
        <linearGradient id="paint3_linear_1021_708" x1="11.9204" y1="30.622" x2="18.9844" y2="19.3141" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E3B356" />
          <stop offset="1" stopColor="#8B6A2D" />
        </linearGradient>
        <linearGradient id="paint4_linear_1021_708" x1="13.7207" y1="22.4134" x2="25.4433" y2="16.1764" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E3B356" />
          <stop offset="1" stopColor="#8B6A2D" />
        </linearGradient>
        <linearGradient id="paint5_linear_1021_708" x1="30.7168" y1="15.9994" x2="41.9865" y2="23.0377" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E3B356" />
          <stop offset="1" stopColor="#8B6A2D" />
        </linearGradient>
        <linearGradient id="paint6_linear_1021_708" x1="21.5313" y1="16.5975" x2="34.7782" y2="17.0542" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E3B356" />
          <stop offset="1" stopColor="#8B6A2D" />
        </linearGradient>
      </defs>
    </svg>

  )
}

function easeBreathe(x) {
  return 1 - Math.pow(1 - x, 4)
}
